import React from 'react';
import {StyleSheet, Text, View} from 'react-native';

type TTextErrorProps = {
  text: string;
};

const TextError = ({text}: TTextErrorProps) => {
  return (
    <View style={styles.viewTextError}>
      <Text style={styles.textError}>{text}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  viewTextError: {
    marginBottom: 5,
    marginLeft: 2,
  },
  textError: {
    fontSize: 26,
    color: '#ff3333',
    fontFamily: 'Nunito',
  },
});

export default TextError;
