import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import {View} from 'react-native';
import {GiftedChat, IMessage} from 'react-native-gifted-chat';

import io from 'socket.io-client';

import {Chat as ChatService, Merchant, Order} from 'services';

import {
  Bubble,
  ButtonScrollBottom,
  ButtonSend,
  Composer,
  Loader,
  TextCounter,
  TextInput,
} from 'components';

import {getQueryVariable} from 'utils/queryParam';
import TextError from 'components/TextError';

type TDimensions = {
  width: number;
  height: number;
};

export type TChatType = 'merchant' | 'client';

type TPageStatus = 'idle' | 'loading' | 'error' | 'resolved';

export type TOrderInfo = {
  client_id: number;
  merchant_id: number;
  merchant_photo: string;
  order_id: number;
  order_token: string;
  restaurant_name: string;
};

const socket = io(process.env.REACT_APP_SOCKET_API, {
  transports: ['websocket'],
  jsonp: false,
});

const Chat = () => {
  const [pageStatus, setPageStatus] = useState<TPageStatus>('idle');
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [dimensions, setdimensions] = useState<TDimensions>({
    width: window.innerWidth,
    height: innerHeight,
  });

  const [orderInfo, setOrderInfo] = useState<TOrderInfo>({
    client_id: 0,
    merchant_id: 0,
    order_id: 0,
    order_token: '',
    restaurant_name: '',
    merchant_photo: '',
  });
  const [conversationId, setConversationId] = useState('');

  const [textInput, setTextInput] = useState('');
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [messageReceived, setMessageReceived] = useState<IMessage[]>([]);

  const chatType = useMemo<TChatType>(() => {
    const type = getQueryVariable('type');

    if (type === 'client') {
      return type;
    }

    return 'merchant';
  }, []);

  const user = useMemo(() => {
    if (chatType === 'client') {
      return {
        _id: orderInfo.client_id,
      };
    }

    return {
      _id: orderInfo.merchant_id,
      name: orderInfo.restaurant_name,
      avatar: orderInfo.merchant_photo,
    };
  }, [chatType, orderInfo]);

  const onSocketConnection = useCallback(
    async (data: TOrderInfo, socket) => {
      const response = await ChatService.establishConnection({
        data: {
          client_id: data.client_id,
          merchant_id: data.merchant_id,
          order_id: data.order_id,
        },
        type: chatType,
        socketId: socket.id,
      });

      if (!response.ok) {
        setPageStatus('error');
        setErrorMessage('Ocorreu um erro, tente atualizar a página');
        return;
      }

      setConversationId(response.data.conversation_id);
      setMessages(previousState =>
        GiftedChat.append(previousState, response.data.messages.reverse()),
      );

      const socketOnEvent = {
        client: 'merchant-client-message',
        merchant: 'client-merchant-message',
      };

      socket.on(socketOnEvent[chatType], function (message) {
        setMessageReceived(message);
      });

      setPageStatus('resolved');
    },
    [chatType],
  );
  const createSocket = useCallback(
    async (data: TOrderInfo) => {
      if (!socket.connected) {
        await socket.on(
          'connect',
          async () => await onSocketConnection(data, socket),
        );
        return;
      }

      await onSocketConnection(data, socket);
    },
    [onSocketConnection],
  );

  const getChatInfo = useCallback(async () => {
    const orderId = getQueryVariable('order_id');

    if (chatType === 'client') {
      return await Order.chatInfo(orderId);
    }

    return await Merchant.chatInfo(orderId);
  }, [chatType]);

  const getOrderInfo = useCallback(async () => {
    const response = await getChatInfo();

    if (!response.ok) {
      setPageStatus('error');
      setErrorMessage('Ocorreu um erro, tente atualizar a página');
      return;
    }

    setOrderInfo(response.data);
    await createSocket(response.data);
  }, [createSocket, getChatInfo]);

  const sendSocket = useCallback(
    (newMessage: IMessage) => {
      const socketEmitEvent = {
        client: 'client-merchant-message',
        merchant: 'merchant-client-message',
      };

      console.log({
        conversation_id: conversationId,
        message: newMessage,
      });

      socket.emit(socketEmitEvent[chatType], {
        conversation_id: conversationId,
        message: newMessage,
      });
    },
    [chatType, conversationId],
  );

  const onSend = useCallback(
    (newMessages: IMessage[] = []) => {
      if (!newMessages.length) {
        return;
      }

      setMessages(previousState =>
        GiftedChat.append(previousState, newMessages),
      );

      sendSocket(newMessages[0]);
    },
    [sendSocket],
  );

  useEffect(() => {
    if (pageStatus === 'idle') {
      setPageStatus('loading');

      getOrderInfo();
    }
  }, [getOrderInfo, pageStatus]);

  useEffect(() => {
    setMessages(previousState =>
      GiftedChat.append(previousState, messageReceived),
    );
  }, [messageReceived]);

  useLayoutEffect(() => {
    window.addEventListener('resize', () =>
      setdimensions({width: window.innerWidth, height: innerHeight}),
    );
  }, []);

  useEffect(() => {
    return () => socket.disconnect();
  }, []);

  return (
    <View style={{width: dimensions.width, height: dimensions.height}}>
      {pageStatus == 'loading' && <Loader />}
      {pageStatus == 'error' && <TextError text={errorMessage ?? 'Erro'} />}
      {pageStatus == 'resolved' && (
        <GiftedChat
          locale={'pt-br'}
          timeFormat={'HH:mm'}
          dateFormat={'DD/MM/YYYY'}
          placeholder={'Digite sua mensagem...'}
          inverted={true}
          maxInputLength={400}
          scrollToBottom={true}
          alwaysShowSend={true}
          renderUsernameOnMessage={true}
          user={user}
          text={textInput}
          messages={messages}
          onInputTextChanged={text => setTextInput(text)}
          onSend={onSend}
          renderLoading={() => <Loader />}
          renderBubble={props => <Bubble props={props} />}
          scrollToBottomComponent={() => <ButtonScrollBottom />}
          renderInputToolbar={props => <TextInput props={props} />}
          renderSend={props => <ButtonSend props={props} />}
          renderComposer={props => (
            <>
              <Composer props={props} />

              <TextCounter length={props.text?.length || 0} />
            </>
          )}
        />
      )}
    </View>
  );
};

export default Chat;
